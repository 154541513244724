
import styled, { css } from 'styled-components';

const Poster = () => css`
    position: relative;
    background: #FFFFFF;
    border-radius: 24px;
    height: ${({ isDesktop }) => (isDesktop ? '100%' : '100%')};
    display: block;
    width: 100%;

    @media (max-height: 850px) {
      height: ${({ isDesktop }) => (isDesktop ? '100%' : '100%')};
    }
`;


export default styled.div([Poster]);