import styled from 'styled-components';

export const Wrapper = styled.button`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LoadModelBtn = styled.button`
    background: #FCFCFC;
    border: 1px solid #D2D2D3;
    border-radius: 8px;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #07031B;
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    font-family: 'BlenderMedium';
    position:absolute;
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    margin: auto;
    svg {
        margin-right: 10px;
    }
    :hover { 
        border: 1px solid #818181;
    }

`;

export const SpinnerContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
`

export const LoadingSpinner = styled.span`
    width: 48px;
    height: 48px;
    border: 5px solid #686868;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

