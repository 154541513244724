
import styled, { css } from 'styled-components';

const HelpLogo = () => css`
    position: relative;
    height:24px;
    width: 24px;
    font-family: SharpSans;
    font-weight: 600;
    right:0px;
    margin-right: 6px;
    vertical-align:middle;
`;


export default styled.i([HelpLogo]);