
import styled, { css } from 'styled-components';

const ARIconSVG = () => css`
    height:16px;
    width:16px;

    @media (max-height: 500px) {
        height:16px;
        width:16px;
    }
`;


export default styled.svg([ARIconSVG]);