
import styled, { css } from 'styled-components';

const CloseIcon = () => css`
    position: relative;
    height: 40px;
    width: 40px;
    top: 0px;
    margin-left: 70px;
    border: 1px solid #D2D2D3;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    &:hover {
        border: 1px solid #818181;
    }
`;


export default styled.div([CloseIcon]);