
import styled, { css } from 'styled-components';

const ButtonWrapper = () => css`
    position: absolute;
    top: 31px;
    width: 100%;
    text-align: center;
    z-index: 1;
`;


export default styled.div([ButtonWrapper]);