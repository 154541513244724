import styled, { css } from 'styled-components';

const ButtonText = () => css`
    color: #07031B;
    font-family: BlenderMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.02em;
    position: relative;
    align-self:center;
    width:100%;
    text-align:center;
    display:flex;
    align-items:center;
    place-content:center;

    @media (max-height: 500px) {
        font-size:18px;
    }
`;

export default styled.div([ButtonText]);