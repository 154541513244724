
import styled, { css } from 'styled-components';

const HeaderLogo = () => css`
    position: relative;
    height:34px;
    width: 144px;
    vertical-align:middle;

    @media (max-width: 400px) {
        width: 100px;
        height: 24px;
    }
`;


export default styled.div([HeaderLogo]);