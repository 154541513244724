
import styled, { css } from 'styled-components';

const ModalWrapper = () => css`
    position: absolute;
    z-index: 9999;
    width:100%;
    height:100%;
    background:white;
    outline: 0;
    justify-content: center;
    display: ${({ show }) => (show ? 'flex' : 'none')};
`;


export default styled.div([ModalWrapper]);