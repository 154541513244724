import React from 'react';
import styled from 'styled-components';
import ModalWrapper from './ModalWrapper.js';
import QRWraper from './QRWraper.js';
import QR from './QR.js';
import QRText from './QRText.js';
import QRDesc from './QRDesc.js';
import CloseIcon from './CloseIcon'
import HelpText from './HelpText'
import HelpLogo from './HelpLogo';
import AlphaLogo from '../AlphaLogo.js';
import { Link } from "react-router-dom";
import Header from './Header.js';
import HeaderLogo from './HeaderLogo';





const Modal = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 3px;
    height: 100%;
    width: 100%;
`;


const modal = ({ show, close, redirectQR, resourceURL }) => {
    function closeModal() {
        close();
    }

    return (
        <ModalWrapper show={show}>
            <Modal>
                <Header>
                    <HelpText>
                        <Link style={{ textDecoration: "none", color: "black" }} to={{ pathname: "https://www.alpha3d.io/how-viewer-works/" }} target="_blank">
                            <HelpLogo>
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" />
                                    <path d="M8.68835 9.79889C8.68835 7.88508 10.2729 6.49829 12.0348 6.49829C13.7966 6.49829 15.3094 7.88508 15.3094 9.79889C15.3094 11.2724 14.0913 11.7439 12.9714 12.9817C12.3338 13.6865 12.0348 14.7892 12.0348 15.2804M12.0348 16.5V18.5" stroke="black" strokeWidth="2" />
                                </svg>
                            </HelpLogo>
                            How it works
                        </Link>
                    </HelpText>

                    <HeaderLogo>
                        <svg viewBox="0 0 144 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M58.1841 25.4031H61.6235L54.0675 6.44531H51.4134L43.8574 25.4031H47.2157L48.7052 21.6115H56.7216L58.1841 25.4031ZM49.8697 18.5783L52.7134 11.3202L55.53 18.5783H49.8697Z" fill="#07031B" />
                            <path d="M65.5188 6.44531H62.5126V25.4031H65.5188V6.44531Z" fill="#07031B" />
                            <path d="M75.472 11.8889C73.3325 11.8889 71.7075 12.8639 70.7597 14.543V12.1597H67.7535V30.0342H70.7597V23.0198C71.7075 24.6989 73.3325 25.6739 75.472 25.6739C78.9927 25.6739 81.7822 22.6136 81.7822 18.7949C81.7822 14.9763 78.9927 11.8889 75.472 11.8889ZM74.822 22.8031C72.4929 22.8031 70.7597 21.0699 70.7597 18.7949C70.7597 16.4929 72.4929 14.7596 74.822 14.7596C77.0699 14.7596 78.8302 16.5471 78.8302 18.7949C78.8302 21.0157 77.0699 22.8031 74.822 22.8031Z" fill="#07031B" />
                            <path d="M90.244 11.8889C88.4837 11.8889 87.0754 12.7555 86.2358 14.2451V6.44531H83.2297V25.4031H86.2358V18.2262C86.2358 16.1409 87.5358 14.7867 89.5128 14.7867C91.4357 14.7867 92.7086 16.1409 92.7086 18.2262V25.4031H95.7418V17.847C95.7418 14.1638 93.6294 11.8889 90.244 11.8889Z" fill="#07031B" />
                            <path d="M108.311 12.1597V14.4888C107.363 12.8368 105.738 11.8889 103.626 11.8889C100.105 11.8889 97.2882 14.9492 97.2882 18.7679C97.2882 22.5865 100.105 25.6739 103.626 25.6739C105.738 25.6739 107.363 24.726 108.311 23.074V25.4031H111.344V12.1597H108.311ZM104.276 22.8031C102.028 22.8031 100.267 21.0157 100.267 18.7679C100.267 16.5471 102.028 14.7596 104.276 14.7596C106.578 14.7596 108.311 16.4929 108.311 18.7679C108.311 21.0699 106.578 22.8031 104.276 22.8031Z" fill="#07031B" />
                            <path d="M116.796 14.3263V16.4117C119.856 15.5721 122.619 16.6283 122.619 19.2283C122.619 21.2053 121.156 22.5865 119.125 22.5865C117.175 22.5865 115.929 21.5303 115.658 20.1761H112.49C112.788 23.426 115.523 25.6739 119.125 25.6739C123.025 25.6739 125.869 23.0198 125.869 19.3095C125.869 16.2221 123.594 13.9201 120.642 13.9472L125.706 8.55775V6.44531H113.004V9.47855H121.237L116.796 14.3263Z" fill="#07031B" />
                            <path d="M133.801 6.44531H127.572V25.4031H133.801C139.19 25.4031 143.28 21.3136 143.28 15.8429C143.28 10.4264 139.19 6.44531 133.801 6.44531ZM134.018 22.3698H130.741V9.47855H134.018C137.484 9.47855 140.03 12.1326 140.03 15.8429C140.03 19.5803 137.457 22.3698 134.018 22.3698Z" fill="#07031B" />
                            <path d="M15.191 5.54566C14.5308 6.20581 13.4605 6.20581 12.8004 5.54566C12.1402 4.88551 12.1402 3.81511 12.8004 3.15496C13.4605 2.49481 14.5308 2.49489 15.191 3.15504C15.8511 3.81519 15.8511 4.88551 15.191 5.54566Z" fill="#07031B" />
                            <path d="M13.9276 30.6473C13.2674 31.3074 12.197 31.3073 11.5369 30.6471C10.8767 29.987 10.8768 28.9168 11.5369 28.2567C12.1971 27.5965 13.2674 27.5965 13.9276 28.2567C14.5877 28.9168 14.5877 29.9871 13.9276 30.6473Z" fill="#07031B" />
                            <path d="M11.7621 2.01117C7.31222 -0.0459677 3.42448 -0.30754 1.39489 1.72204C-2.20764 5.32457 1.40849 14.7816 9.47175 22.8448C17.535 30.9081 26.992 34.5242 30.5945 30.9217C32.871 28.6452 32.265 24.031 29.4759 18.908L28.2346 20.4685C29.9622 23.8682 30.5685 26.9127 29.7742 28.9158C25.7109 29.3509 18.9312 25.8161 12.8524 19.7373C6.57542 13.4603 3.01104 6.436 3.72921 2.4269C5.51964 1.8794 8.03208 2.32759 10.8486 3.60144C10.9823 3.03779 11.269 2.50304 11.7087 2.06334C11.7263 2.04571 11.7441 2.02832 11.7621 2.01117Z" fill="#07031B" />
                            <path d="M29.5169 13.9368C32.2465 9.14486 32.9981 4.98048 31.0675 3.04995C27.9194 -0.0982399 18.8307 3.88621 10.7674 11.9495C2.70416 20.0127 -1.2803 29.1014 1.8679 32.2496C3.49455 33.8763 6.70718 33.5987 10.5449 31.8381C10.5103 31.8064 10.4763 31.7738 10.4428 31.7403C10.0687 31.3662 9.80534 30.9233 9.65273 30.4524C7.54048 31.3675 5.68821 31.7453 4.30919 31.4872C4.40777 27.6576 8.09878 21.3138 13.9784 15.4342C20.0226 9.38992 26.5575 5.65855 30.3472 5.76521C30.571 7.59444 29.7783 10.1656 28.1711 13.0411C28.6189 13.1963 29.0394 13.4528 29.397 13.8104C29.4383 13.8517 29.4783 13.8939 29.5169 13.9368Z" fill="#07031B" />
                            <path d="M28.3059 17.2936C27.6457 17.9537 26.5754 17.9537 25.9153 17.2936C25.2551 16.6334 25.2551 15.5631 25.9153 14.9029C26.5754 14.2428 27.6456 14.2426 28.3058 14.9028C28.9659 15.5629 28.966 16.6334 28.3059 17.2936Z" fill="#07031B" />
                        </svg>
                    </HeaderLogo>

                    <CloseIcon onClick={closeModal}>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.292787 1.29308C0.480314 1.10561 0.734622 1.00029 0.999786 1.00029C1.26495 1.00029 1.51926 1.10561 1.70679 1.29308L6.99979 6.58608L12.2928 1.29308C12.385 1.19757 12.4954 1.12139 12.6174 1.06898C12.7394 1.01657 12.8706 0.988985 13.0034 0.987831C13.1362 0.986677 13.2678 1.01198 13.3907 1.06226C13.5136 1.11254 13.6253 1.18679 13.7192 1.28069C13.8131 1.37458 13.8873 1.48623 13.9376 1.60913C13.9879 1.73202 14.0132 1.8637 14.012 1.99648C14.0109 2.12926 13.9833 2.26048 13.9309 2.38249C13.8785 2.50449 13.8023 2.61483 13.7068 2.70708L8.41379 8.00008L13.7068 13.2931C13.8889 13.4817 13.9897 13.7343 13.9875 13.9965C13.9852 14.2587 13.88 14.5095 13.6946 14.6949C13.5092 14.8803 13.2584 14.9855 12.9962 14.9878C12.734 14.99 12.4814 14.8892 12.2928 14.7071L6.99979 9.41408L1.70679 14.7071C1.51818 14.8892 1.26558 14.99 1.00339 14.9878C0.741188 14.9855 0.490376 14.8803 0.304968 14.6949C0.11956 14.5095 0.0143906 14.2587 0.0121121 13.9965C0.00983372 13.7343 0.110629 13.4817 0.292787 13.2931L5.58579 8.00008L0.292787 2.70708C0.105316 2.51955 0 2.26525 0 2.00008C0 1.73492 0.105316 1.48061 0.292787 1.29308Z" fill="#0A083A" />
                        </svg>
                    </CloseIcon>
                </Header>
                <QRWraper>
                    <QR>
                        <img src={redirectQR.url} height="180px" width="180px"></img>
                    </QR>
                    <QRText>
                        Scan this QR code with your phone
                    </QRText>

                    <QRDesc>
                        Open your camera application and aim towards this QR code. After a successful scan, your native AR application will open and lets you see all the available 3D models.
                        <br /><br />
                        You can also open <Link style={{ textDecoration: "underline", color: "black" }} to={{ pathname: resourceURL }} target="_blank"> {resourceURL}</Link> in your phone browser
                    </QRDesc>
                </QRWraper>


            </Modal>
        </ModalWrapper>

    )
}

export default modal;
