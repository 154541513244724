import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { USDZLoader } from 'three/examples/jsm/loaders/USDZLoader';

import { Wrapper, LoadModelBtn, SpinnerContainer, LoadingSpinner } from './ModelViewer.styles';

import HdrFile from "./hdr.hdr";

const ModelViewer = ({ androidModel, iosModel }) => {
  const canvasRef = useRef();
  const [modelLoaded, setModelLoaded] = useState(false);
  const [arMode, setARMode] = useState(false);
  const [showLoading, setShowLoading] = useState('none');

  useEffect(() => {
    if (modelLoaded) {
      // Initialize Three.js scene
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 10;

      scene.background = new THREE.Color(0xffffff);

      const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true });
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.outputEncoding = THREE.sRGBEncoding;

      // Load the GLB model
      const loader = new GLTFLoader();
      loader.load(androidModel, gltf => {
        // Get the bounding box of the model
        const bbox = new THREE.Box3().setFromObject(gltf.scene);

        // Calculate the center point of the bounding box
        const center = bbox.getCenter(new THREE.Vector3());

        // Move the model to the center of the scene
        gltf.scene.position.set(-center.x, -center.y, -center.z);

        // Add the model to the scene
        scene.add(gltf.scene);

        // Calculate the size of the model's bounding box
        const size = bbox.getSize(new THREE.Vector3()).length();

        // Calculate the diagonal field of view based on the model's size
        const diagonalFov = 2 * Math.atan(size / (2 * camera.position.z)) * (180 / Math.PI);

        // Set the camera's field of view based on the diagonal field of view
        camera.fov = diagonalFov;

        // Calculate the aspect ratio of the screen
        const aspectRatio = window.innerWidth / window.innerHeight;

        // Adjust the camera's aspect ratio to match the screen
        camera.aspect = aspectRatio;

        // const distance = size / Math.tan(Math.PI * camera.fov / 360);
        // camera.position.set(0, 0, distance);

        // Update the camera's projection matrix
        camera.updateProjectionMatrix();

        setShowLoading('none');
      });


      new RGBELoader().load(HdrFile, texture => {
        const gen = new THREE.PMREMGenerator(renderer)
        const envMap = gen.fromEquirectangular(texture).texture
        scene.environment = envMap
        //scene.background = envMap

        texture.dispose()
        gen.dispose()
      })

      // Add controls to the scene
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.5;

      // Animate and render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
        controls.update();
      };
      animate();
    }
  }, [modelLoaded]);

  const onLoadButtonClick = () => {
    setModelLoaded(true);
    setShowLoading('inline-block');
  };

  return (
    <div>
      <canvas ref={canvasRef} />
      <SpinnerContainer>
        <LoadingSpinner style={{display: showLoading}} />
      </SpinnerContainer>

      {!modelLoaded && (
        <LoadModelBtn onClick={onLoadButtonClick}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99935 8.16673C7.70602 8.16673 7.41268 8.0934 7.14602 7.9534L2.80602 5.6134C2.41268 5.40006 2.16602 4.98006 2.16602 4.5134C2.16602 4.04673 2.41268 3.62673 2.80602 3.4134L7.14602 1.0734C7.67935 0.786732 8.31935 0.786732 8.84602 1.0734L13.186 3.4134C13.5793 3.62673 13.826 4.04673 13.826 4.5134C13.826 4.98006 13.5793 5.40006 13.186 5.6134L8.84602 7.9534C8.58602 8.10006 8.29268 8.16673 7.99935 8.16673ZM7.99935 1.8534C7.87268 1.8534 7.73935 1.88673 7.61935 1.9534L3.28602 4.28673C3.17268 4.34673 3.16602 4.4734 3.16602 4.5134C3.16602 4.5534 3.17268 4.68006 3.28602 4.7334L7.62602 7.0734C7.86602 7.20006 8.13935 7.20006 8.37935 7.0734L12.7193 4.7334C12.826 4.6734 12.8393 4.54673 12.8393 4.5134C12.8393 4.4734 12.8327 4.34673 12.7193 4.2934L8.37935 1.9534C8.25935 1.88673 8.12602 1.8534 7.99935 1.8534Z" fill="#07031B" />
            <path d="M6.52065 15.1468C6.33398 15.1468 6.14065 15.1002 5.96732 15.0135L1.92732 13.0002C1.25398 12.6602 0.833984 11.9802 0.833984 11.2268V7.4135C0.833984 6.98017 1.05398 6.58684 1.42065 6.36017C1.79398 6.1335 2.24065 6.1135 2.62732 6.30684L6.66732 8.32017C7.34065 8.66017 7.76065 9.3335 7.76065 10.0935V13.9068C7.76065 14.3402 7.54065 14.7335 7.17398 14.9602C6.97398 15.0868 6.74732 15.1468 6.52065 15.1468ZM2.07398 7.1735C2.02065 7.1735 1.97398 7.1935 1.94732 7.20684C1.90732 7.2335 1.83398 7.2935 1.83398 7.4135V11.2268C1.83398 11.6002 2.04065 11.9402 2.37398 12.1068L6.40732 14.1268C6.51398 14.1802 6.60065 14.1402 6.64065 14.1202C6.68065 14.0935 6.75398 14.0335 6.75398 13.9135V10.1002C6.75398 9.72684 6.54732 9.38684 6.21398 9.22017L2.18065 7.20017C2.14065 7.18017 2.10732 7.1735 2.07398 7.1735Z" fill="#07031B" />
            <path d="M9.48023 15.1467C9.25357 15.1467 9.0269 15.0867 8.8269 14.96C8.46023 14.7333 8.24023 14.34 8.24023 13.9067V10.0933C8.24023 9.34 8.66023 8.66 9.33357 8.32L13.3669 6.3C13.7536 6.10667 14.2069 6.12667 14.5736 6.35334C14.9402 6.58 15.1602 6.97334 15.1602 7.40667V11.22C15.1602 11.9733 14.7402 12.6533 14.0669 12.9933L10.0336 15.0133C9.86023 15.1067 9.6669 15.1467 9.48023 15.1467ZM13.9269 7.17334C13.8936 7.17334 13.8602 7.18 13.8202 7.2L9.7869 9.22C9.45357 9.38667 9.2469 9.72 9.2469 10.1V13.9133C9.2469 14.0333 9.32023 14.0933 9.36023 14.12C9.40023 14.1467 9.4869 14.18 9.59357 14.1267L13.6269 12.1067C13.9602 11.94 14.1669 11.6 14.1669 11.2267V7.41334C14.1669 7.29334 14.0936 7.23334 14.0536 7.20667C14.0269 7.19334 13.9802 7.17334 13.9269 7.17334Z" fill="#07031B" />
          </svg>
          Load 3D
        </LoadModelBtn>
      )}
    {/* {modelLoaded && (
      <LoadModelBtn onClick={() => setARMode(true)}>
        View in AR
      </LoadModelBtn>
    )}
    {arMode && (
      <a-scene embedded arjs='sourceType: webcam;'>
        <a-marker preset='hiro'>
          <a-entity
            gltf-model={androidModel}
            scale='0.01 0.01 0.01'
            position='0 0 0'
          />
        </a-marker>
        <a-entity camera></a-entity>
      </a-scene>
    )} */}
    </div>
  );
};

export default ModelViewer;
