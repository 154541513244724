
import styled, { css } from 'styled-components';

const HelpText = () => css`
    position: relative;
    font-family: BlenderMedium;
    width:112px;
    color: #07031B;
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-top: -10px;
`;


export default styled.div([HelpText]);