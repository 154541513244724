
import styled, { css } from 'styled-components';

const QRText = () => css`
    top: 4%;
    font-weight: 500;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    position: relative;
    font-family: BlenderBook;
    color: #07031B;
    letter-spacing: -0.05em;

    @media (max-width: 580px) {
    font-size:35px;
    }

`;


export default styled.div([QRText]);