
import styled, { css } from 'styled-components';

const Button = () => css`
    position: relative;
    height: 40px;
    margin: auto;
    width: 40%;
    max-width: 188px;


    @media (max-height: 340px) {
    max-height: 40px;
    max-width: 188px;
    }

    @media (max-height: 500px) and (min-height: 340px) {
    max-height: 40px;
    max-width: 188px;
    }

    @media (max-width: 600px) {
      width: 88%;
      max-width: 188px;
      min-width: 188px;
    }

    @media (min-width: 1336px) {
      width: 13%;
    }
`;


export default styled.div([Button]);