
import styled, { css } from 'styled-components';

const ButtonWrapper = () => css`
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    height:100%;
    width:100%;
`;


export default styled.div([ButtonWrapper]);