
import styled, { css } from 'styled-components';

const ButtonLayout = () => css`
    position: relative;
    display:flex;
    justify-content:center;
    height:100%;
    width:100%;
    text-align:center;

    background: #FCFCFC;
    border: 1px solid #D2D2D3;
    border-radius: 8px;

    &:hover {
        border: 1px solid #818181;
    }
`;


export default styled.div([ButtonLayout]);