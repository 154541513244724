
import styled, { css } from 'styled-components';

const ButtonsGroup = () => css`
    display: ${({ isDesktop }) => (isDesktop ? 'flex' : 'none')};
    position: absolute;
    bottom: 100px;
    width: 100%;
    height: ${({ isDesktop }) => (isDesktop ? 'unset' : 'unset')};
    
    @media (max-height: 850px) {
      height: ${({ isDesktop }) => (isDesktop ? 'unset' : 'unset')};
    } 
`;


export default styled.div([ButtonsGroup]);