import axios from 'axios';

const api = process.env.REACT_APP_ALPHAAR_API_URL;

// export function getResource(resourceId) {
//     return fetch(api + resourceId)
//         .then(data => data.json())
// }

export function getResource(resourceId) {
    return axios.get(api + "/resources/" + resourceId)
        .then(response => response.data)
        .catch(error => {
            // Handle any errors
            console.error(error);
        });
}



export function createRedirectQR(redirectURL) {
    // return fetch("https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=" + redirectURL + "&choe=UTF-8", {
    return fetch("https://image-charts.com/chart?chs=150x150&cht=qr&chl=" + redirectURL + "&choe=UTF-8", {
        method: 'POST'
    })
        .then(data => data)
}
