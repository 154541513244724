
import styled, { css } from 'styled-components';

const QRDesc = () => css`
    top: 6%;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    font-family: BlenderBook;
    text-align:center;
    align-self: center;
    max-width: 60%;
    color: #07031B;
    letter-spacing: -0.03em;
    width: 506px;
    margin-bottom: 100px;

    @media (max-width: 580px) {
    max-width: 85%;
    font-size:16px;
  }
`;


export default styled.div([QRDesc]);