import styled, { css } from 'styled-components';

const QRWraper = () => css`
    position: relative;
    width: 100%;
    height: calc(100% - 34px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;


export default styled.div([QRWraper]);