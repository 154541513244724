
import styled, { css } from 'styled-components';

const Header = () => css`
    position: relative;
    font-family: SharpSansBold;
    font-size:12px;
    font-weight: 600;
    width:calc(100% - 64px);
    height: 34px;
    display:flex;
    align-items:center;
    padding: 26px 32px 0 32px;
    justify-content:space-between;

    @media (max-width: 500px) {
        padding: 26px 10px 0 10px;
        width:calc(100% - 20px);
    }
`;


export default styled.div([Header]);