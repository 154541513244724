
import styled, { css } from 'styled-components';

const QR = () => css`
    position: relative;
    z-index: 1050;
    top:3%;
    align-self: center;
`;


export default styled.div([QR]);