import styled, { css } from 'styled-components';

const Container = () => css`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;


//opacity: ${({ active }) => (active ? '1' : '.5')};
export default styled.div([Container]);