
import styled, { css } from 'styled-components';

const ARIcon = () => css`
    margin-right:10px;
    display: flex;
    align-items: center;
`;


export default styled.i([ARIcon]);