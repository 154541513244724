import React, { useState, useEffect } from 'react';
import Modal from './components/modal/Modal.js';
import Poster from './components/Poster.js';
import Button from './components/button/Button';
import ButtonText from './components/button/ButtonText';
import Container from './components/Container';
import { Link, useParams } from "react-router-dom";
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';
import { getResource, createRedirectQR } from './Service.js';
import '@google/model-viewer';
import ButtonsGroup from './components/button/ButtonsGroup';
import ButtonWrapper from './components/button/ButtonWrapper';
import ButtonLayout from './components/button/ButtonLayout';
import AlphaLogo from './components/AlphaLogo'
import ARIcon from './components/ARIcon.js';
import ARIconSVG from './components/ARIconSVG.js';
import AlphaLogoSVG from './components/AlphaLogoSVG.js';
import LazyLoadIcon from './assets/lazy-load-icon.svg';
import ModelViewer from './components/modelViewer/index.js';

import Glb from './components/modelViewer/back.glb';


const App = () => {
  let { resourceId } = useParams();
  const [show, showModal] = useState(false);
  const [sceneViewerLink, changeSceneViewerLink] = useState("");
  const [resource, setResource] = useState("");
  const [redirectQR, setRedirectQR] = useState("");
  const [displayIOSArButton, setDisplayIOSArButton] = useState("none");
  const [displayAndroidArButton, setDisplayAndroidArButton] = useState("none");
  const [silenResourceIds, setSilenResourceIds] = useState(['9bc23cd1-9d32-4190-bba9-e7fdbb4aeacd', '7d130df6-6429-4808-90f0-66f35f327d40', '478862bf-60fe-470c-af1e-a892f3d689ab', '52f40b8d-cfb7-425e-a7c6-a3b1b2b9f133', '476bc92a-0bad-4263-ac4a-69aae350c9d4', 'b27c6856-324d-48a3-9a8b-9334353556c6', '45ef0f89-8ef7-43e8-8745-ff44811173c6', 'ad806e67-2221-41d6-a083-167b69ec3080', 'b7eab738-3552-49a6-a572-f2dcde5f9ac4', '7213e1e7-2b89-4fe9-83f0-20e8c8905f1c', '83845011-245f-4e9c-bc2c-326de4baf487', 'dcc523ab-7517-4509-b04c-150450f9d437', '5f34e744-044d-4559-a031-805e9eda79a2', 'a35c1292-7d27-4a95-ab27-b3fbc945d8be', '39a78be6-9da0-41d4-a77a-ad6ca0bf9f7c', '1c11409b-4e60-492a-9856-b78d834af375']);

  useEffect(() => {
    let mounted = true;
    getResource(resourceId)
      .then(resource => {
        if (mounted) {
          setResource(resource)

          // Check if it is Silen's resource and add gtag for for those resources
          if(silenResourceIds.includes(resourceId)) {
            const script = document.createElement("script");
            script.innerHTML = `
              (function(w,d,s,l,i){
                w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WF3SMCN');
            `;
            document.head.appendChild(script);

            const noscriptIframe = document.createElement("noscript");
            noscriptIframe.innerHTML = `
              <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WF3SMCN"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `;
            document.body.insertBefore(noscriptIframe, document.body.firstChild);
          }

          createRedirectQR(resource?.resourceURL)
            .then(data => {
              setRedirectQR(data);
            })
        }
      })
    return () => mounted = false;
  }, [])

  //check is browser supports AR
  useEffect(() => {
    const a = document.createElement("a");
    if (!isDesktop) {
      if (a.relList.supports("ar")) {
        setDisplayIOSArButton('flex')
      } else {
        setDisplayAndroidArButton('flex')

      }
    }
  }, [])

  const handleSceneViewerLink = (e) => {
    if (isDesktop) {
      e.preventDefault()
      showModal(true)
    }
  }

  const handleLazyLoad = (e) => {
    document.querySelector('#lazy-load').dismissPoster()
  }

  return (


    <Container style={{
      backgroundImage: `url(${resource?.posterLocation})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center"
    }}  active={!show}>
      <Modal show={show} redirectQR={redirectQR} redirectLink={resource.showCaseURL} resourceURL={resource.resourceURL} close={() => {
        showModal(false);
      }}></Modal>


      <AlphaLogo>
        <Link style={{ textDecoration: "none" }} to={{ pathname: "https://alphaar.io" }} target="_blank" >
          <AlphaLogoSVG viewBox="0 0 144 34">
            <path d="M58.1841 25.4031H61.6235L54.0675 6.44531H51.4134L43.8574 25.4031H47.2157L48.7052 21.6115H56.7216L58.1841 25.4031ZM49.8697 18.5783L52.7134 11.3202L55.53 18.5783H49.8697Z" fill="#07031B" />
            <path d="M65.5188 6.44531H62.5126V25.4031H65.5188V6.44531Z" fill="#07031B" />
            <path d="M75.472 11.8889C73.3325 11.8889 71.7075 12.8639 70.7597 14.543V12.1597H67.7535V30.0342H70.7597V23.0198C71.7075 24.6989 73.3325 25.6739 75.472 25.6739C78.9927 25.6739 81.7822 22.6136 81.7822 18.7949C81.7822 14.9763 78.9927 11.8889 75.472 11.8889ZM74.822 22.8031C72.4929 22.8031 70.7597 21.0699 70.7597 18.7949C70.7597 16.4929 72.4929 14.7596 74.822 14.7596C77.0699 14.7596 78.8302 16.5471 78.8302 18.7949C78.8302 21.0157 77.0699 22.8031 74.822 22.8031Z" fill="#07031B" />
            <path d="M90.244 11.8889C88.4837 11.8889 87.0754 12.7555 86.2358 14.2451V6.44531H83.2297V25.4031H86.2358V18.2262C86.2358 16.1409 87.5358 14.7867 89.5128 14.7867C91.4357 14.7867 92.7086 16.1409 92.7086 18.2262V25.4031H95.7418V17.847C95.7418 14.1638 93.6294 11.8889 90.244 11.8889Z" fill="#07031B" />
            <path d="M108.311 12.1597V14.4888C107.363 12.8368 105.738 11.8889 103.626 11.8889C100.105 11.8889 97.2882 14.9492 97.2882 18.7679C97.2882 22.5865 100.105 25.6739 103.626 25.6739C105.738 25.6739 107.363 24.726 108.311 23.074V25.4031H111.344V12.1597H108.311ZM104.276 22.8031C102.028 22.8031 100.267 21.0157 100.267 18.7679C100.267 16.5471 102.028 14.7596 104.276 14.7596C106.578 14.7596 108.311 16.4929 108.311 18.7679C108.311 21.0699 106.578 22.8031 104.276 22.8031Z" fill="#07031B" />
            <path d="M116.796 14.3263V16.4117C119.856 15.5721 122.619 16.6283 122.619 19.2283C122.619 21.2053 121.156 22.5865 119.125 22.5865C117.175 22.5865 115.929 21.5303 115.658 20.1761H112.49C112.788 23.426 115.523 25.6739 119.125 25.6739C123.025 25.6739 125.869 23.0198 125.869 19.3095C125.869 16.2221 123.594 13.9201 120.642 13.9472L125.706 8.55775V6.44531H113.004V9.47855H121.237L116.796 14.3263Z" fill="#07031B" />
            <path d="M133.801 6.44531H127.572V25.4031H133.801C139.19 25.4031 143.28 21.3136 143.28 15.8429C143.28 10.4264 139.19 6.44531 133.801 6.44531ZM134.018 22.3698H130.741V9.47855H134.018C137.484 9.47855 140.03 12.1326 140.03 15.8429C140.03 19.5803 137.457 22.3698 134.018 22.3698Z" fill="#07031B" />
            <path d="M15.191 5.54566C14.5308 6.20581 13.4605 6.20581 12.8004 5.54566C12.1402 4.88551 12.1402 3.81511 12.8004 3.15496C13.4605 2.49481 14.5308 2.49489 15.191 3.15504C15.8511 3.81519 15.8511 4.88551 15.191 5.54566Z" fill="#07031B" />
            <path d="M13.9276 30.6473C13.2674 31.3074 12.197 31.3073 11.5369 30.6471C10.8767 29.987 10.8768 28.9168 11.5369 28.2567C12.1971 27.5965 13.2674 27.5965 13.9276 28.2567C14.5877 28.9168 14.5877 29.9871 13.9276 30.6473Z" fill="#07031B" />
            <path d="M11.7621 2.01117C7.31222 -0.0459677 3.42448 -0.30754 1.39489 1.72204C-2.20764 5.32457 1.40849 14.7816 9.47175 22.8448C17.535 30.9081 26.992 34.5242 30.5945 30.9217C32.871 28.6452 32.265 24.031 29.4759 18.908L28.2346 20.4685C29.9622 23.8682 30.5685 26.9127 29.7742 28.9158C25.7109 29.3509 18.9312 25.8161 12.8524 19.7373C6.57542 13.4603 3.01104 6.436 3.72921 2.4269C5.51964 1.8794 8.03208 2.32759 10.8486 3.60144C10.9823 3.03779 11.269 2.50304 11.7087 2.06334C11.7263 2.04571 11.7441 2.02832 11.7621 2.01117Z" fill="#07031B" />
            <path d="M29.5169 13.9368C32.2465 9.14486 32.9981 4.98048 31.0675 3.04995C27.9194 -0.0982399 18.8307 3.88621 10.7674 11.9495C2.70416 20.0127 -1.2803 29.1014 1.8679 32.2496C3.49455 33.8763 6.70718 33.5987 10.5449 31.8381C10.5103 31.8064 10.4763 31.7738 10.4428 31.7403C10.0687 31.3662 9.80534 30.9233 9.65273 30.4524C7.54048 31.3675 5.68821 31.7453 4.30919 31.4872C4.40777 27.6576 8.09878 21.3138 13.9784 15.4342C20.0226 9.38992 26.5575 5.65855 30.3472 5.76521C30.571 7.59444 29.7783 10.1656 28.1711 13.0411C28.6189 13.1963 29.0394 13.4528 29.397 13.8104C29.4383 13.8517 29.4783 13.8939 29.5169 13.9368Z" fill="#07031B" />
            <path d="M28.3059 17.2936C27.6457 17.9537 26.5754 17.9537 25.9153 17.2936C25.2551 16.6334 25.2551 15.5631 25.9153 14.9029C26.5754 14.2428 27.6456 14.2426 28.3058 14.9028C28.9659 15.5629 28.966 16.6334 28.3059 17.2936Z" fill="#07031B" />
          </AlphaLogoSVG>
        </Link>
      </AlphaLogo>

      {/* <ModelViewer modelPath={"https://alphaapp-panel-uploaded-models.s3.eu-north-1.amazonaws.com/test/38e110d7-944d-4747-9738-6aa6d6176f2a/Dab_Motors_HD%20%281%29.glb"} /> */}
      <ModelViewer androidModel={resource?.androidModel?.fileLocation} iosModel={resource?.iosModel?.fileLocation} />
      {/* <Poster isDesktop={isDesktop}>
        <model-viewer poster={resource?.posterLocation}
          id="lazy-load"
          camera-controls
          auto-rotate
          quick-look-browsers="safari chrome"
          ar
          magic-leap
          interaction-prompt="auto"
          ar-modes="webxr scene-viewer quick-look"
          ar-scale="auto"
          reveal="manual"
          style={{
            height: "100%",
            width: "100%",
            contain: "none",
            overflow: "unset !important"
          }}
          loading="eager"
          shadow-intensity="1"
          poster={resource?.posterLocation}
          src={resource?.androidModel?.fileLocation}
          ios-src={resource?.iosModel?.fileLocation}>


          <div
            style={{
              backgroundImage: `url(${resource?.posterLocation})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}
            id="lazy-load-poster" slot="poster">
          </div>
          <div style={{
            backgroundColor: '#FCFCFC',
            border: '1px solid #D2D2D3',
            borderRadius: '8px',
            color: '#07031B',
            fontWeight: '500',
            fontSize: '18px',
            lineHeight: '18px',
            fontFamily: 'BlenderMedium',
            boxShadow: 'none',
            backgroundImage: `url(${LazyLoadIcon})`,
            backgroundSize: "16px 16px",
            backgroundPosition: "8% 50%"
          }} id="button-load" slot="poster" onClick={handleLazyLoad}>Turn 3D viewer ON</div>

          <Button slot="ar-button" style={{ position: "absolute", display: "flex", bottom: "1%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <ButtonWrapper>
              <ButtonLayout>
                <ButtonText>
                  <ARIcon>
                    <ARIconSVG viewBox="0 0 16 16">
                      <path d="M14.6673 6.50016C14.394 6.50016 14.1673 6.2735 14.1673 6.00016V4.66683C14.1673 2.94683 13.054 1.8335 11.334 1.8335H4.66732C2.94732 1.8335 1.83398 2.94683 1.83398 4.66683V6.00016C1.83398 6.2735 1.60732 6.50016 1.33398 6.50016C1.06065 6.50016 0.833984 6.2735 0.833984 6.00016V4.66683C0.833984 2.3735 2.37398 0.833496 4.66732 0.833496H11.334C13.6273 0.833496 15.1673 2.3735 15.1673 4.66683V6.00016C15.1673 6.2735 14.9407 6.50016 14.6673 6.50016Z" fill="#07031B" />
                      <path d="M11.334 15.1667H4.66732C2.37398 15.1667 0.833984 13.6267 0.833984 11.3333V10C0.833984 9.72667 1.06065 9.5 1.33398 9.5C1.60732 9.5 1.83398 9.72667 1.83398 10V11.3333C1.83398 13.0533 2.94732 14.1667 4.66732 14.1667H11.334C13.054 14.1667 14.1673 13.0533 14.1673 11.3333V10C14.1673 9.72667 14.394 9.5 14.6673 9.5C14.9407 9.5 15.1673 9.72667 15.1673 10V11.3333C15.1673 13.6267 13.6273 15.1667 11.334 15.1667Z" fill="#07031B" />
                      <path d="M8.0004 8.71997C7.91373 8.71997 7.82706 8.69994 7.74706 8.65328L4.21373 6.61328C3.97373 6.47328 3.89373 6.16663 4.03373 5.92663C4.17373 5.68663 4.4804 5.60662 4.71373 5.74662L7.99373 7.6466L11.2537 5.75997C11.4937 5.61997 11.8004 5.70661 11.9337 5.93994C12.0737 6.17994 11.9871 6.48662 11.7537 6.62662L8.24706 8.65328C8.17373 8.69328 8.08706 8.71997 8.0004 8.71997Z" fill="#07031B" />
                      <path d="M8 12.3464C7.72667 12.3464 7.5 12.1197 7.5 11.8464V8.21973C7.5 7.94639 7.72667 7.71973 8 7.71973C8.27333 7.71973 8.5 7.94639 8.5 8.21973V11.8464C8.5 12.1197 8.27333 12.3464 8 12.3464Z" fill="#07031B" />
                      <path d="M8.00015 12.5C7.61349 12.5 7.23349 12.4134 6.92682 12.2467L4.79349 11.0601C4.15349 10.7067 3.66016 9.86004 3.66016 9.1267V6.86669C3.66016 6.14003 4.16015 5.29339 4.79349 4.93339L6.92682 3.7467C7.54015 3.4067 8.46015 3.4067 9.07349 3.7467L11.2068 4.93339C11.8468 5.28672 12.3401 6.13336 12.3401 6.86669V9.1267C12.3401 9.85337 11.8402 10.7001 11.2068 11.0601L9.07349 12.2467C8.76682 12.4134 8.38682 12.5 8.00015 12.5ZM7.41348 4.6267L5.28015 5.81335C4.95348 5.99335 4.66016 6.50003 4.66016 6.86669V9.1267C4.66016 9.50004 4.95348 10 5.28015 10.18L7.41348 11.3667C7.72015 11.5334 8.28016 11.5334 8.58682 11.3667L10.7202 10.18C11.0468 10 11.3401 9.49337 11.3401 9.1267V6.86669C11.3401 6.49336 11.0468 5.99335 10.7202 5.81335L8.58682 4.6267C8.28016 4.46004 7.72015 4.46004 7.41348 4.6267Z" fill="#07031B" />
                    </ARIconSVG>
                  </ARIcon>
                  View in your space
                </ButtonText>
              </ButtonLayout>
            </ButtonWrapper>
          </Button>
        </model-viewer>
      </Poster> */}

      <Button style={{ position: "absolute", display: displayIOSArButton, bottom: "80px", left: "50%", transform: "translate(-50%, -50%)" }}>
        <ButtonWrapper>
          <a rel="ar" style={{ textDecoration: "none" }} href={resource?.iosModel?.fileLocation}>
          <img />
            <ButtonLayout>
              <ButtonText>
                <ARIcon>
                  <ARIconSVG viewBox="0 0 16 16">
                    <path d="M14.6673 6.50016C14.394 6.50016 14.1673 6.2735 14.1673 6.00016V4.66683C14.1673 2.94683 13.054 1.8335 11.334 1.8335H4.66732C2.94732 1.8335 1.83398 2.94683 1.83398 4.66683V6.00016C1.83398 6.2735 1.60732 6.50016 1.33398 6.50016C1.06065 6.50016 0.833984 6.2735 0.833984 6.00016V4.66683C0.833984 2.3735 2.37398 0.833496 4.66732 0.833496H11.334C13.6273 0.833496 15.1673 2.3735 15.1673 4.66683V6.00016C15.1673 6.2735 14.9407 6.50016 14.6673 6.50016Z" fill="#07031B" />
                    <path d="M11.334 15.1667H4.66732C2.37398 15.1667 0.833984 13.6267 0.833984 11.3333V10C0.833984 9.72667 1.06065 9.5 1.33398 9.5C1.60732 9.5 1.83398 9.72667 1.83398 10V11.3333C1.83398 13.0533 2.94732 14.1667 4.66732 14.1667H11.334C13.054 14.1667 14.1673 13.0533 14.1673 11.3333V10C14.1673 9.72667 14.394 9.5 14.6673 9.5C14.9407 9.5 15.1673 9.72667 15.1673 10V11.3333C15.1673 13.6267 13.6273 15.1667 11.334 15.1667Z" fill="#07031B" />
                    <path d="M8.0004 8.71997C7.91373 8.71997 7.82706 8.69994 7.74706 8.65328L4.21373 6.61328C3.97373 6.47328 3.89373 6.16663 4.03373 5.92663C4.17373 5.68663 4.4804 5.60662 4.71373 5.74662L7.99373 7.6466L11.2537 5.75997C11.4937 5.61997 11.8004 5.70661 11.9337 5.93994C12.0737 6.17994 11.9871 6.48662 11.7537 6.62662L8.24706 8.65328C8.17373 8.69328 8.08706 8.71997 8.0004 8.71997Z" fill="#07031B" />
                    <path d="M8 12.3464C7.72667 12.3464 7.5 12.1197 7.5 11.8464V8.21973C7.5 7.94639 7.72667 7.71973 8 7.71973C8.27333 7.71973 8.5 7.94639 8.5 8.21973V11.8464C8.5 12.1197 8.27333 12.3464 8 12.3464Z" fill="#07031B" />
                    <path d="M8.00015 12.5C7.61349 12.5 7.23349 12.4134 6.92682 12.2467L4.79349 11.0601C4.15349 10.7067 3.66016 9.86004 3.66016 9.1267V6.86669C3.66016 6.14003 4.16015 5.29339 4.79349 4.93339L6.92682 3.7467C7.54015 3.4067 8.46015 3.4067 9.07349 3.7467L11.2068 4.93339C11.8468 5.28672 12.3401 6.13336 12.3401 6.86669V9.1267C12.3401 9.85337 11.8402 10.7001 11.2068 11.0601L9.07349 12.2467C8.76682 12.4134 8.38682 12.5 8.00015 12.5ZM7.41348 4.6267L5.28015 5.81335C4.95348 5.99335 4.66016 6.50003 4.66016 6.86669V9.1267C4.66016 9.50004 4.95348 10 5.28015 10.18L7.41348 11.3667C7.72015 11.5334 8.28016 11.5334 8.58682 11.3667L10.7202 10.18C11.0468 10 11.3401 9.49337 11.3401 9.1267V6.86669C11.3401 6.49336 11.0468 5.99335 10.7202 5.81335L8.58682 4.6267C8.28016 4.46004 7.72015 4.46004 7.41348 4.6267Z" fill="#07031B" />
                  </ARIconSVG>
                </ARIcon>
                View in your space
              </ButtonText>
            </ButtonLayout>
          </a>
        </ButtonWrapper>
      </Button>

      <Button style={{ position: "absolute", display: displayAndroidArButton, bottom: "80px", left: "50%", transform: "translate(-50%, -50%)" }}>
        <ButtonWrapper>
        <a style={{ textDecoration: "none" }} href={`intent://arvr.google.com/scene-viewer/1.0?file=${resource?.androidModel?.fileLocation}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`}>
            <ButtonLayout>
              <ButtonText>
                <ARIcon>
                  <ARIconSVG viewBox="0 0 16 16">
                    <path d="M14.6673 6.50016C14.394 6.50016 14.1673 6.2735 14.1673 6.00016V4.66683C14.1673 2.94683 13.054 1.8335 11.334 1.8335H4.66732C2.94732 1.8335 1.83398 2.94683 1.83398 4.66683V6.00016C1.83398 6.2735 1.60732 6.50016 1.33398 6.50016C1.06065 6.50016 0.833984 6.2735 0.833984 6.00016V4.66683C0.833984 2.3735 2.37398 0.833496 4.66732 0.833496H11.334C13.6273 0.833496 15.1673 2.3735 15.1673 4.66683V6.00016C15.1673 6.2735 14.9407 6.50016 14.6673 6.50016Z" fill="#07031B" />
                    <path d="M11.334 15.1667H4.66732C2.37398 15.1667 0.833984 13.6267 0.833984 11.3333V10C0.833984 9.72667 1.06065 9.5 1.33398 9.5C1.60732 9.5 1.83398 9.72667 1.83398 10V11.3333C1.83398 13.0533 2.94732 14.1667 4.66732 14.1667H11.334C13.054 14.1667 14.1673 13.0533 14.1673 11.3333V10C14.1673 9.72667 14.394 9.5 14.6673 9.5C14.9407 9.5 15.1673 9.72667 15.1673 10V11.3333C15.1673 13.6267 13.6273 15.1667 11.334 15.1667Z" fill="#07031B" />
                    <path d="M8.0004 8.71997C7.91373 8.71997 7.82706 8.69994 7.74706 8.65328L4.21373 6.61328C3.97373 6.47328 3.89373 6.16663 4.03373 5.92663C4.17373 5.68663 4.4804 5.60662 4.71373 5.74662L7.99373 7.6466L11.2537 5.75997C11.4937 5.61997 11.8004 5.70661 11.9337 5.93994C12.0737 6.17994 11.9871 6.48662 11.7537 6.62662L8.24706 8.65328C8.17373 8.69328 8.08706 8.71997 8.0004 8.71997Z" fill="#07031B" />
                    <path d="M8 12.3464C7.72667 12.3464 7.5 12.1197 7.5 11.8464V8.21973C7.5 7.94639 7.72667 7.71973 8 7.71973C8.27333 7.71973 8.5 7.94639 8.5 8.21973V11.8464C8.5 12.1197 8.27333 12.3464 8 12.3464Z" fill="#07031B" />
                    <path d="M8.00015 12.5C7.61349 12.5 7.23349 12.4134 6.92682 12.2467L4.79349 11.0601C4.15349 10.7067 3.66016 9.86004 3.66016 9.1267V6.86669C3.66016 6.14003 4.16015 5.29339 4.79349 4.93339L6.92682 3.7467C7.54015 3.4067 8.46015 3.4067 9.07349 3.7467L11.2068 4.93339C11.8468 5.28672 12.3401 6.13336 12.3401 6.86669V9.1267C12.3401 9.85337 11.8402 10.7001 11.2068 11.0601L9.07349 12.2467C8.76682 12.4134 8.38682 12.5 8.00015 12.5ZM7.41348 4.6267L5.28015 5.81335C4.95348 5.99335 4.66016 6.50003 4.66016 6.86669V9.1267C4.66016 9.50004 4.95348 10 5.28015 10.18L7.41348 11.3667C7.72015 11.5334 8.28016 11.5334 8.58682 11.3667L10.7202 10.18C11.0468 10 11.3401 9.49337 11.3401 9.1267V6.86669C11.3401 6.49336 11.0468 5.99335 10.7202 5.81335L8.58682 4.6267C8.28016 4.46004 7.72015 4.46004 7.41348 4.6267Z" fill="#07031B" />
                  </ARIconSVG>
                </ARIcon>
                View in your space
              </ButtonText>
            </ButtonLayout>
          </a>
        </ButtonWrapper>
      </Button>

      <ButtonsGroup isDesktop={isDesktop}>
        <Button>
          <ButtonWrapper>
            <Link rel="ar" style={{ textDecoration: "none" }} onClick={handleSceneViewerLink} to={{ pathname: sceneViewerLink }} target="_blank" >
              <ButtonLayout>
                <ButtonText>
                  <ARIcon>
                    <ARIconSVG viewBox="0 0 16 16">
                      <path d="M14.6673 6.50016C14.394 6.50016 14.1673 6.2735 14.1673 6.00016V4.66683C14.1673 2.94683 13.054 1.8335 11.334 1.8335H4.66732C2.94732 1.8335 1.83398 2.94683 1.83398 4.66683V6.00016C1.83398 6.2735 1.60732 6.50016 1.33398 6.50016C1.06065 6.50016 0.833984 6.2735 0.833984 6.00016V4.66683C0.833984 2.3735 2.37398 0.833496 4.66732 0.833496H11.334C13.6273 0.833496 15.1673 2.3735 15.1673 4.66683V6.00016C15.1673 6.2735 14.9407 6.50016 14.6673 6.50016Z" fill="#07031B" />
                      <path d="M11.334 15.1667H4.66732C2.37398 15.1667 0.833984 13.6267 0.833984 11.3333V10C0.833984 9.72667 1.06065 9.5 1.33398 9.5C1.60732 9.5 1.83398 9.72667 1.83398 10V11.3333C1.83398 13.0533 2.94732 14.1667 4.66732 14.1667H11.334C13.054 14.1667 14.1673 13.0533 14.1673 11.3333V10C14.1673 9.72667 14.394 9.5 14.6673 9.5C14.9407 9.5 15.1673 9.72667 15.1673 10V11.3333C15.1673 13.6267 13.6273 15.1667 11.334 15.1667Z" fill="#07031B" />
                      <path d="M8.0004 8.71997C7.91373 8.71997 7.82706 8.69994 7.74706 8.65328L4.21373 6.61328C3.97373 6.47328 3.89373 6.16663 4.03373 5.92663C4.17373 5.68663 4.4804 5.60662 4.71373 5.74662L7.99373 7.6466L11.2537 5.75997C11.4937 5.61997 11.8004 5.70661 11.9337 5.93994C12.0737 6.17994 11.9871 6.48662 11.7537 6.62662L8.24706 8.65328C8.17373 8.69328 8.08706 8.71997 8.0004 8.71997Z" fill="#07031B" />
                      <path d="M8 12.3464C7.72667 12.3464 7.5 12.1197 7.5 11.8464V8.21973C7.5 7.94639 7.72667 7.71973 8 7.71973C8.27333 7.71973 8.5 7.94639 8.5 8.21973V11.8464C8.5 12.1197 8.27333 12.3464 8 12.3464Z" fill="#07031B" />
                      <path d="M8.00015 12.5C7.61349 12.5 7.23349 12.4134 6.92682 12.2467L4.79349 11.0601C4.15349 10.7067 3.66016 9.86004 3.66016 9.1267V6.86669C3.66016 6.14003 4.16015 5.29339 4.79349 4.93339L6.92682 3.7467C7.54015 3.4067 8.46015 3.4067 9.07349 3.7467L11.2068 4.93339C11.8468 5.28672 12.3401 6.13336 12.3401 6.86669V9.1267C12.3401 9.85337 11.8402 10.7001 11.2068 11.0601L9.07349 12.2467C8.76682 12.4134 8.38682 12.5 8.00015 12.5ZM7.41348 4.6267L5.28015 5.81335C4.95348 5.99335 4.66016 6.50003 4.66016 6.86669V9.1267C4.66016 9.50004 4.95348 10 5.28015 10.18L7.41348 11.3667C7.72015 11.5334 8.28016 11.5334 8.58682 11.3667L10.7202 10.18C11.0468 10 11.3401 9.49337 11.3401 9.1267V6.86669C11.3401 6.49336 11.0468 5.99335 10.7202 5.81335L8.58682 4.6267C8.28016 4.46004 7.72015 4.46004 7.41348 4.6267Z" fill="#07031B" />
                    </ARIconSVG>
                  </ARIcon>
                  View in your space
                </ButtonText>
              </ButtonLayout>
            </Link>
          </ButtonWrapper>
        </Button>
      </ButtonsGroup>
      {/* <a rel="ar" href="https://alphaapp-panel-uploaded-models.s3.eu-north…47-9738-6aa6d6176f2a/Dab_Motors_HD%20%281%29.usdz">
        <img/>
        <p>test</p>
      </a> */}

    </Container >
  );
}
export default App;
